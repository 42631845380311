import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Link, Box } from "@material-ui/core/";
import Data from "../../modules/data";
import Fade from 'react-reveal/Zoom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles(theme => ({
  footer: {
    "@global": {
      ul: {
        margin: 0,
        padding: 0
      },
      li: {
        listStyle: "none"
      }
    },

    borderTop: `1px solid ${theme.palette.divider}`,
    // margin: theme.spacing(6),
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    },
    transition: "padding-top .25s, padding-bottom .25s",
    transitionTimingFunction: "ease-in-out",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    // flexWrap: "nowrap",
    flexDirection: "column",

  },
  studioLogo: {
    display: "flex",
    width: "384px",
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    },
    transition: "width .25s",
    transitionTimingFunction: "ease-in-out",
  },

  logoAndOptions: {
    display: "flex",
    flexGrow: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column"
    },
  },

  options: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-evenly",
    margin: "auto",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
    },
  },
  notices: {
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",

    justifyContent: "center",
    marginTop: "48px",
    margin: "auto"
  },
  optionsFont: {
    [theme.breakpoints.down('sm')]: {
      fontSize: "175%"
    },
  }
}));

export default function Footer(props) {
  const classes = useStyles(props);
  const footerData = Data["footer"];
  const options = footerData["options"]
  const notices = footerData["notices"]

  return (
    <Container maxWidth="lg" component="footer" className={classes.footer}>
      <div className={classes.logoAndOptions}>
        <Fade>
          <Link href={footerData["logo_link"]} color="inherit">
            <LazyLoadImage className={classes.studioLogo} alt="Logo" src={footerData["logo"]} />
          </Link>
        </Fade>
        <div className={classes.options}>
          {options.map(option => (
            <Link href={option["href"]}>
              <Typography className={classes.optionsFont} color="primary" variant="h5" gutterBottom>
                {option["name"]}
              </Typography>
            </Link>
          ))}
        </div>
      </div>

      <div className={classes.notices}>
        {notices.map(notice => (
          <Typography color="primary" variant="body2" gutterBottom>
            {notice}
          </Typography>
        ))}
      </div>




      {/* <Grid container spacing={4}>
        {footers.map(footer => (
          <Grid item xs={8} sm={3} key={footer.title}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {footer.title}
            </Typography>
            <ul>
              {footer.description.map(item => (
                <li key={item.name}>
                  <Link
                    // onClick={() => {
                    //   props.scrollToRef(item.name);
                    // }}
                    variant="subtitle1"
                    color="textSecondary"

                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </Grid>
        ))} */}

      {/* {credits.map(credit => (
          <Grid item xs={8} sm={3} key={credit.name}>
            <Typography variant="h6" color="textPrimary" gutterBottom>
              {credit.name}
            </Typography>
            <ul>
              <li key={credit.link}>
                <Link
                  href={credit.link}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {credit.link}
                </Link>
              </li>
            </ul>
          </Grid>
        ))} */}

      {/* </Grid> */}
      {/* Build number. */}
      <Box className={classes.noticeItem} mt={4}>
        {process.env.REACT_APP_GIT_SHA != null ? (
          <span>build {process.env.REACT_APP_GIT_SHA}</span>
        ) : (
            ""
          )}
      </Box>
    </Container>
  );
}
