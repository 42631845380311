import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import { Button, Divider } from "@material-ui/core";
import { LazyLoadImage } from 'react-lazy-load-image-component';

import Reveal from 'react-reveal/Jump';
import Data, { isGameReleased } from "../../modules/data";


const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    // justifyContent: "space-evenly",
    // position: "relative",
    flexGrow: 1,
    // height: "100vh",
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),

    backgroundColor: "rgb(255, 255, 255)",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column"
    }
  },
  actionButtons: {
    // margin: "-px",
    display: "flex",
    flexGrow: 1,
    // justifyContent: "center",
    alignItems: "flex-end",
    [theme.breakpoints.down('xs')]: {
      alignItems: "center",
    },
    textTransform: "uppercase",
    margin: "auto",
  },
  actionButton: {
    // margin: "auto",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    flexGrow: 1,
    backgroundColor: "rgb(0, 0, 0)",
    [theme.breakpoints.up('xs')]: {
      width: "384px",
      marginLeft: theme.spacing(2),
      top: "50%",
    },

    [theme.breakpoints.down('xs')]: {
      width: "30vh",
      margin: "auto",
      marginTop: theme.spacing(2),
      // top: "50%",
    },

    border: "6px solid #ffffff",
    boxShadow: '0 0 0 4px rgb(0, 0, 0)',
  },

  buyActionText: {
    // margin: "auto",
    display: "flex",
    [theme.breakpoints.up('xs')]: {
      marginTop: "8px",
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
      alignItems: "center"
    },
    fontWeight: 700
  },
  image: {
    alignSelf: "flex-end",
    [theme.breakpoints.down('xs')]: {
      alignSelf: "center"
    },
  },
  imageTaglineBox: {
    display: "flex",
    // flexShrink: 1,
    // flexGrow: 1,
    justifyContent: "flex-start",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "center",
    },
    flexDirection: "column"
  },

  divider: {

    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      margin: "auto"
    },
    border: `4px solid ${theme.palette.secondary.main}`,
    display: "flex",
    flexGrow: 1,
    height: "100%",
  }

}));


function DataButton(props) {
  if ("afterRelease" in props) {

    if (isGameReleased() != props.afterRelease) {
      return null;
    }
  }

  return (
    <Button onClick={() => {
      if (props.link) {
        window.location.href = props.link;

      }
    }}
      className={props.buttonClass} variant="contained">
      <Typography color="primary" className={props.textClass} variant="h3">
        {props.text}
      </Typography>
    </Button>
  );
}

export default function Buy(props) {
  const classes = useStyles();
  return (
    <Container ref={props.ref} maxWidth="100%" className={classes.container}>
      <Reveal>
        <div className={classes.imageTaglineBox}>
          <LazyLoadImage src={props.buy.image} className={classes.image} />
          <Typography color="secondary" className={classes.buyActionText} variant="h4">
            {props.buy.tagline}
          </Typography>
        </div>
        <Divider className={classes.divider} />
        {Data["buy"]["buttons"].map(button => (
          <DataButton buttonClass={classes.actionButton} textClass={classes.buyActionText} {...button}></DataButton>
        ))}
      </Reveal>
    </Container>
  );
}
