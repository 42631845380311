import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import GetAppIcon from '@material-ui/icons/GetApp';

import { Container, GridList, GridListTile, Link, IconButton } from "@material-ui/core";
import Reveal from 'react-reveal/Reveal';
import { Divider } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const useStyles = makeStyles(theme => ({
    container: {
        display: "flex",
        // justifyContent: "space-evenly",
        // position: "relative",
        flexGrow: 1,
        // height: "100vh",
        paddingTop: theme.spacing(12),
        paddingBottom: theme.spacing(12),
        backgroundColor: "rgb(240, 240, 240)"
    },
    insideContainer: {
        display: "flex",
        // flexGrow: 1,
        justifyContent: "center",
        alignItems: "stretch",
        flexDirection: "column"

        // margin: "auto",
        // alignItems: "flex-end"
    },
    imageBox: {
        display: "flex",
        justifyContent: "center"

        // flexShrink: 1,
        // maxWidth: "1280px",
        // maxHeight: "100%",


    },
    textBox: {
        display: "flex",
        flexShrink: 20,
        flexDirection: "column",
        justifyContent: "flex-start"
    },
    aboutImage: {
        alignSelf: "center",
        width: "100%",
        maxWidth: "400px",
        height: "auto"
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(2),
        border: `2px solid ${theme.palette.secondary.main}`,
    },
    gridTileStyle: {
        position: 'relative',
        [theme.breakpoints.up("xl")]: {
            width: "600px !important",
            height: "300px !important"
        }
        // overflow: 'hidden',
        // height: '100% !important'
    },
    title: {
        display: "flex",
        justifyContent: "flex-start",
        flexGrow: 1,
        alignItems: "center",
        "flexDirection": "wrap"
    },
    iconButton: {
        marginLeft: theme.spacing(1),

    },
    downloadButton: {
        fontSize: "64px",
    }
}));

export default function Images(props) {
    const classes = useStyles();
    return (
        <div className={props.className} ref={props.ref}>
            {/* <Reveal bottom cascade> */}
            <Container maxWidth="100%" className={classes.container} style={{ "backgroundColor": props.backgroundColor }}>
                <div className={classes.insideContainer}>

                    <div className={classes.title}>
                        <Typography variant="h3">{props.name}</Typography>
                        <IconButton onClick={() => {
                            if (props.download) {
                                window.open(props.download)
                            }
                        }} className={classes.iconButton}>
                            {props.download ? <GetAppIcon className={classes.downloadButton} /> : null}
                        </IconButton>
                    </div>
                    <Divider className={classes.divider}></Divider>

                    <div className={classes.imageBox}>
                        <GridList>
                            {props.images.map(image => (
                                <GridListTile className={classes.gridTileStyle} key={image}>
                                    <Link href={image.highres} target="_blank"><LazyLoadImage style={{ width: "100%", height: "auto" }} src={image.lowres} alt="Press Kit Image" /></Link>
                                </GridListTile>
                            ))}
                        </GridList>
                    </div>
                </div>
                {/* </ScrollAnimation> */}
            </Container>
            {/* </Reveal> */}

        </div >
    );
}
