import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Toolbar, Typography, Button } from "@material-ui/core";
import Sticky from "react-stickynode";
import ButtonDropDown from "../dropdownbutton";
import Data from "../../modules/data";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  header: {
    border: "6px solid black",
    fontSmoothing: "antialiased",
    fontStyle: "normal",
    textTransform: "uppercase",
    // margin: "0 auto",
    maxWidth: "1280px",

    transition: "height .2s",
    [theme.breakpoints.up("lg")]: {
      top: `${theme.spacing(10)}px`,
      boxShadow: "0 0 0 4px rgb(255, 255, 255)"
    },
    left: "50%",
    transform: "translate(-50%)",

    transition: "max-width .25s, top .15s, boxShadow .45s",
    transitionTimingFunction: "ease-in-out"
  },

  headerStickied: {
    [theme.breakpoints.up("lg")]: {
      top: 0,
      boxShadow: "0 0 0 0px rgb(255, 255, 255)",
      maxWidth: "100%"
    }
  },

  headerButtonBox: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexGrow: "1",
    justifyContent: "space-evenly",
    lineHeight: `${theme.spacing(4)}px`, //24
    paddingLeft: `${theme.spacing(1)}px`, //8
    top: "-1.5px",
    verticalAlign: "middle",
    textRendering: "optimizeLegibility",
    color: theme.palette.secondary.main
  },

  headerButton: {
    flexGrow: 1,
    alignItems: "stretch"
  },

  faviconButton: {
    marginLeft: "-24px"
  },

  favicon: {
    width: "64px",
    imageRendering: "pixelated"
  },
  text: {
    margin: "auto",
    [theme.breakpoints.down("xs")]: {
      fontSize: "3vw"
    }
  }
}));

export default function Navbar(props) {
  const classes = useStyles(props);
  const community_links = Data["community"];
  const [stickied, setStickied] = useState(false);
  const history = useHistory();
  return (
    <Sticky
      innerZ={1}
      onStateChange={status => {
        setStickied(status.status == 2);
      }}
    >
      <AppBar
        color="default"
        elevation={0}
        className={`${classes.header} ${
          stickied ? classes.headerStickied : {}
        }`}
      >
        <Toolbar>
          <Button
            className={classes.faviconButton}
            onClick={() => {
              history.push("/");
            }}
          >
            <img alt="BadLads" src="/favicon.png" className={classes.favicon} />
          </Button>

          <nav className={classes.headerButtonBox}>
            {/* <Button
              className={classes.headerButton}
              onClick={() => {
                history.push("/")
                props.scrollToRef("about");
              }}
            >
              <Typography className={classes.text} variant="h5">
                ABOUT
              </Typography>
            </Button> */}
            {/* <Button
              className={classes.headerButton}
              onClick={() => {
                history.push("/")
                props.scrollToRef("jobs");
              }}
            >
              <Typography className={classes.text} variant="h5">
                THE JOBS
              </Typography>
            </Button> */}
            <Button
              className={classes.headerButton}
              onClick={() => {
                history.push("/presskit");
                window.scrollTo(0, 0);
              }}
            >
              <Typography className={classes.text} variant="h5">
                PRESS KIT
              </Typography>
            </Button>
            <Button
              className={classes.headerButton}
              href={Data["devblog_link"]}
              target="_blank"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography className={classes.text} variant="h5">
                DEVBLOG
              </Typography>
            </Button>
            <ButtonDropDown
              className={classes.headerButton}
              items={community_links}
            >
              <Typography className={classes.text} variant="h5">
                COMMUNITY
              </Typography>
            </ButtonDropDown>
            <Button
              className={classes.headerButton}
              href={Data["roadmap_link"]}
              target="_blank"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography className={classes.text} variant="h5">
                ROADMAP
              </Typography>
            </Button>
            <Button
              className={classes.headerButton}
              href={Data["docs_link"]}
              target="_blank"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              <Typography className={classes.text} variant="h5">
                DOCS
              </Typography>
            </Button>
          </nav>
        </Toolbar>
      </AppBar>
    </Sticky>
  );
}
