import React from "react";

import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import GameData from "../../modules/data";

import { Button } from "@material-ui/core";

import Data, { isGameReleased } from "../../modules/data";
import Buy from "../../components/buy";
import CoolImage from "../../components/coolimage";

const useStyles = makeStyles(theme => ({
  "@global": {
    body: {
      width: "100%",
      height: "100%",
      margin: "0px",
      padding: "0px",
      overflowX: "hidden"
    }
  },
  jobButton: {
    marginTop: theme.spacing(4)
  },
  cardGrid: {
    marginTop: theme.spacing(3)
  },
  container: {
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(2)
  },

  card: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  cardMedia: {
    paddingTop: "56.25%" // 16:9
  },
  cardContent: {
    flexGrow: 1
  },

  divider: { margin: theme.spacing(6) },

  heroContent: {
    padding: theme.spacing(42, 0, 6)
    // zIndex: -1,
    // overflow: "hidden"
  },

  headerVideo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    minWidth: "100%",
    minHeight: "100%",
    verticalAlign: "baseline",
    overflow: "hidden",
    zIndex: -1
  },
  headerSection: {
    position: "relative",
    minHeight: "780px",
    height: "100vh",
    overflow: "hidden"
    // boxShadow: "0 0 30px 5px #999;",
  },
  headerText: {},
  headerParagraphText: {
    margin: 1,
    verticalAlign: "center",
    textAlign: "center"
  },
  heroContainer: {
    textAlign: "center",
    width: "100%",
    height: "100%"
  },
  logo: {
    marginTop: -theme.spacing(2),
    // [theme.breakpoints.up('xs')]: {
    //   // marginTop: theme.spacing(0),

    // },

    maxWidth: "512px",
    maxHeight: "512px",
    // [theme.breakpoints.down("xs")]: {
    width: "100%"
    // }
  },

  actionButtons: {
    margin: "24px",
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    textTransform: "uppercase"
  },
  actionButton: {
    flexGrow: 1,
    width: "128px",
    margin: "24px",
    // [theme.breakpoints.down("xs")]: {
    margin: "8px",
    // },
    border: "6px solid black",
    boxShadow: "0 0 0 4px rgb(255, 255, 255)"
  },

  actionText: {
    flexGrow: 0
  },
  actionButtonText: {
    fontWeight: 700,
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px"
    }
  },
  steamLogo: {
    // color: "#000000",
    // zIndex: -4,
    width: "192px",
    filter: "brightness(100%) contrast(150%) invert(150%)"
  },
  headerSubtitle: {
    marginTop: theme.spacing(2)
  }
}));

function DataButton(props) {
  if ("afterRelease" in props) {
    if (isGameReleased() !== props.afterRelease) {
      return null;
    }
  }

  return (
    <Button
      onClick={() => {
        if (props.link) {
          if (props.newTab) {
            window.open(props.link, "_blank");
          } else {
            window.location.href = props.link;
          }
        }
      }}
      className={props.buttonClass}
      variant="contained"
    >
      <Typography className={props.textClass} variant="h5">
        {props.text}
      </Typography>
    </Button>
  );
}

export default function Home(props) {
  const classes = useStyles(props);

  const addLineBreaks = string =>
    string.split("\n").map((text, index) => (
      <React.Fragment key={`${text}-${index}`}>
        {text}
        <br />
      </React.Fragment>
    ));

  return (
    <React.Fragment>
      <div className={classes.headerSection}>
        <div className={classes.heroContent}>
          <Container className={classes.heroContainer}>
            <img
              className={classes.logo}
              src="assets/logo.png"
              alt={GameData["name"]}
            />
            {Data["header"]["subtitle"] != null ? (
              <Typography
                className={classes.headerSubtitle}
                color="primary"
                variant="h5"
              >
                {addLineBreaks(Data["header"]["subtitle"])}
              </Typography>
            ) : null}
            <div className={classes.actionButtons}>
              {Data["header"]["buttons"].map(button => (
                <DataButton
                  buttonClass={classes.actionButton}
                  textClass={classes.actionButtonText}
                  {...button}
                ></DataButton>
              ))}
            </div>
            <div className={classes.platforms}>
              <Typography color="primary">Early Access On</Typography>
              <img
                className={classes.steamLogo}
                alt="Steam Logo"
                src="assets/steam.svg"
              />
            </div>
          </Container>
        </div>
        <video
          className={classes.headerVideo}
          autoPlay="autoplay"
          loop="loop"
          muted="muted"
          playsInline="playsinline"
          poster="assets/GameBackground.jpg"
        >
          <source src={"/assets/GameBackground.mp4"} type="video/mp4" />
        </video>
      </div>
      {Data["frontpage"].map(data => (
        <CoolImage {...data}></CoolImage>
      ))}

      {/* <Carousel media={["/assets/GameBackground.png"]} /> */}
      <Buy buy={Data["buy"]} />

      {/* <Jobs ref={props.refMap["jobs"]} /> */}
    </React.Fragment>
  );
}
