import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
let theme = createMuiTheme({
  palette: {
    background: {
      default: "#000"
    },
    // type: 'light',
    text: {
      primary: "#000000",
      secondary: "#ffffff"
    },
    paper: {
      primary: "#000000",
      secondary: "#ffffff"
    },
    drawerPaper: { background: "#ffffff" },
    primary: { main: "#ffffff", contrastText: "#000000" },
    secondary: { main: "#000000" }
  },
  typography: {
    fontFamily: "Reem Kufi, Arial"
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": {
          fontFamily: "Reem Kufi, Arial",
          fontDisplay: "swap"
        },
      }
    },
    MuiButton: {
      root: {
        borderRadius: 0
      }
    }
  }
});

export default responsiveFontSizes(theme);
