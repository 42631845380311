import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import { Container } from "@material-ui/core";
import { Divider } from '@material-ui/core';
import Markdown from "../markdown";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    // justifyContent: "space-evenly",
    // position: "relative",
    flexGrow: 1,
    // height: "100vh",
    [theme.breakpoints.up("sm")]: {
      paddingTop: theme.spacing(28),
      paddingBottom: theme.spacing(12),
    },

    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(12)
    },

    backgroundColor: "rgb(200, 200, 200)"
  },
  insideContainer: {
    display: "flex",
    // flexGrow: 1,
    justifyContent: "center",
    alignItems: "stretch",
    // margin: "auto",
    // alignItems: "flex-end"
  },
  aboutGameBox: {
    display: "flex",
    // maxWidth: "1280px",
    // maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    },

  },
  textBox: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      flexShrink: 20,
    },
    flexDirection: "column",
    justifyContent: "flex-start"
  },

  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    border: `2px solid ${theme.palette.secondary.main}`,
  }
}));

export default function PressKitContainer(props) {
  const classes = useStyles();
  return (
    <div className={props.className} ref={props.ref}>
      <Container maxWidth="100%" className={classes.container} style={{ "backgroundColor": props.about.backgroundColor }}>
        {/* <ScrollAnimation animateIn="fadeIn" duration={5}> */}
        <div className={classes.insideContainer}>
          <div className={classes.aboutGameBox}>
            <div className={classes.textBox}>

              {props.about.title ? (
                <React.Fragment>
                  <Typography variant="h4" color="secondary">
                    <Markdown>{props.about.title}</Markdown>
                  </Typography>
                  <Divider className={classes.divider} />
                </React.Fragment>
              ) : null}

              {props.about.description ? <React.Fragment>
                <Typography variant="h5" color="secondary">
                  <Markdown>{props.about.description}</Markdown>
                  {props.children}

                </Typography>
              </React.Fragment> : null}

            </div>
          </div>
        </div>
        {/* </ScrollAnimation> */}
      </Container>
    </div>
  );
}
