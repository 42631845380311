import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Container } from "@material-ui/core";
import Reveal from "react-reveal/Reveal";
import { Divider } from "@material-ui/core";
import Markdown from "../markdown";
import LazyLoad from "react-lazyload";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexGrow: 1,
    paddingTop: theme.spacing(12),
    paddingBottom: theme.spacing(12),
    backgroundColor: "rgb(225, 225, 225)"
  },

  insideContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center"
  },

  aboutGameBox: {
    display: "flex",
    maxWidth: "1280px",
    maxHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },

  textBox: {
    display: "flex",
    flexShrink: 20,
    flexDirection: "column",

    justifyContent: "flex-start"
  },

  aboutImage: {
    alignSelf: "center",
    width: "100%",
    maxWidth: "400px",
    height: "auto"
  },

  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    border: `2px solid ${theme.palette.secondary.main}`
  }
}));

export default function CoolImage(props) {
  const classes = useStyles();
  return (
    <div className={props.className} ref={props.ref}>
      {/* Add placeholder tag to LazyLoad. Setup auto-compress system */}
      <LazyLoad throttle={200} height="100%">
        <Container
          className={classes.container}
          style={{
            maxWidth: "100%",
            background: "no-repeat center center",
            backgroundSize: "cover",
            backgroundColor: "black",
            backgroundImage: `url(${props.image})`,
            minHeight: `${
              "css_min_height" in props ? props.css_min_height : "auto"
            }`,
            filter: `${"css_filter" in props ? props.css_filter : "auto"}`
            // Enable display flex if css_justify_content or css_align_items properties exist.
          }}
        >
          <div
            className={classes.insideContainer}
            style={{
              justifyContent: `${
                "css_justify_content" in props
                  ? props.css_justify_content
                  : "auto"
              }`,
              alignItems: `${
                "css_align_items" in props ? props.css_align_items : "auto"
              }`
            }}
          >
            <Reveal bottom cascade>
              <div>
                <Typography variant="h2" color={props.md_header_color}>
                  <Markdown>{props.md_header}</Markdown>
                </Typography>
                <Divider className={classes.divider} />

                <Typography variant="h5" color={props.md_subtitle_color}>
                  <Markdown>{props.md_subtitle}</Markdown>
                  {props.children}
                </Typography>
              </div>
            </Reveal>
          </div>
        </Container>
      </LazyLoad>
    </div>
  );
}
